import React from "react";
import { createGlobalStyle } from "styled-components";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import { PageDivider } from "./PageDivider";
import { FrontPageElement } from "./FrontPageElement";
import { H1 } from "./common/Heading";
import { PrimaryColor } from "./variables";
import { Lyngdotten } from "./pages/Lyngdotten";
import { Wrapper } from "./styleguide/Wrapper";
import { Stein } from "./pages/Stein";
import ScrollToTop from "./ScrollToTop";
import { Lyngeliten } from "./pages/Lyngeliten";
import { SpacerVertical } from "./styleguide/Spacer";

const GlobalStyle = createGlobalStyle`
  
  *,
*::after, 
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
    @media only screen and (max-width: 37.5em) { font-size: 50%; };
}

body {
    box-sizing: border-box;
    padding: 3rem;

    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.7;
    color: ${PrimaryColor};
    background: url("/img/wood.jpg");
    background-size: 100vw;
}

a:link,
a:visited {
    color: black;
    background-color: inherit;
    text-decoration: none;
    transition: all .2s;
}

::selection {
    background-color: white;
    color: black;
}
  `;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <GlobalStyle />
      <Wrapper>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/lyngdotten">
            <Lyngdotten />
          </Route>
          <Route path="/lyngeliten">
            <Lyngeliten />
          </Route>
          <Route path="/stein">
            <Stein />
          </Route>
          <Route path="/">
            <H1>Lyngdotten</H1>
            <PageDivider>
              <FrontPageElement
                alt="Bilde av lyngdotten"
                src="img/lyngdotten-small.jpg"
                description="Om Lyngdotten"
                href="/lyngdotten"
              />
            </PageDivider>
            <PageDivider>
              <FrontPageElement
                alt="Bilde av lyngeliten, et lite trehus"
                src="img/lyngeliten.png"
                description="Lyngeliten"
                href="/lyngeliten"
              />
            </PageDivider>
            <PageDivider>
              <FrontPageElement
                description="Stein"
                alt="Bilde av Stein"
                src="img/stein-small.jpg"
                href="/stein"
              />
            </PageDivider>
          </Route>
        </Switch>
      </Wrapper>
      <SpacerVertical>
        <p
          style={{
            maxWidth: "300px",
            backgroundColor: "white",
            margin: "0 auto",
            textAlign: "center",
            borderRadius: "5px",
            padding: "1rem",
          }}
        >
          Design fra Tobias Rusås Olsen
        </p>
      </SpacerVertical>
    </Router>
  );
}

export default App;
