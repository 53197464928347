import React from "react";
import { H1 } from "../common/Heading";
import { SpacerVertical } from "../styleguide/Spacer";
import { P } from "../styleguide/P";
import differenceInCalendarYears from "date-fns/differenceInCalendarYears";
import { BackButton } from "../styleguide/BackButton";

export function Stein() {
  return (
    <div>
      <H1>Stein</H1>
      <P>Hei.</P>
      <P>
        Jeg er mannen i huset. Såkalt sjef, men det kan vel diskuteres.
        <img src="/Bilder/Stein.jpg-for-web-NORMAL.jpg" alt="" />
      </P>
      <P>
        Jeg er {differenceInCalendarYears(new Date(), new Date(1953, 11, 21))}{" "}
        år og glad i hus og hage.
      </P>
      <P>
        Kontakt meg gjerne på{" "}
        <a href="mailto:stein@Lyngdotten.com">stein@Lyngdotten.com</a>
      </P>

      <SpacerVertical>
        <BackButton to="/">Tilbake</BackButton>
      </SpacerVertical>
    </div>
  );
}
