import React from "react";
import { BackButton } from "../styleguide/BackButton";
import { H1 } from "../common/Heading";
import { Image } from "../styleguide/Image";
import { P } from "../styleguide/P";

export function Lyngeliten() {
  return (
    <div>
      <H1>Lyngeliten</H1>
      <div
        style={{
          maxWidth: "60rem",
          margin: "0 auto",
        }}
      >
        <Image src="img/lyngeliten.png" alt="Bilde av trehytten lyngeliten" />
      </div>
      <P>Lyngeliten er et lite trehus</P>
      <P>Mer info om lyngeliten kommer!</P>
      <P>
        Lyngeliten ble flyttet i 2020 av pensjonistene. I tillegg fikk den en
        oppgradering i form av maling, og en flott platting foran
        inngangspartiet.
      </P>
      <BackButton to="/">Tilbake</BackButton>
    </div>
  );
}
