import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledFrontPageImageWrapper = styled.div`
  width: 25rem;
  height: 25rem;
  border-radius: 100%;
  overflow: hidden;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: inherit;
  outline: none;
  border: none;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 56.25em) {
    flex-direction: column;
  }
`;

const StyledDescription = styled.div`
  margin-left: 3rem;
  font-size: 5rem;
  color: white;

  @media (max-width: 56.25em) {
    margin-left: 0;
    margin-top: 3rem;
  }
`;

export const FrontPageElement = ({ description, href, ...rest }: any) => {
  return (
    <StyledButton to={href}>
      <StyledFrontPageImageWrapper>
        <StyledImage {...rest} />
      </StyledFrontPageImageWrapper>
      <StyledDescription>{description}</StyledDescription>
    </StyledButton>
  );
};
