import React from "react";
import { H1 } from "../common/Heading";
import { SpacerVertical } from "../styleguide/Spacer";
import { P } from "../styleguide/P";
import { Image } from "../styleguide/Image";
import { BackButton } from "../styleguide/BackButton";

export function Lyngdotten() {
  return (
    <div>
      <H1>Lyngdotten</H1>

      <Image src="img/lyngdotten-big.JPG" alt="Bilde av lyngdotten" />

      <P>Her bor vi.</P>

      <P>
        Huset vårt ble bygget i 1915 - opprinnelig som hytte for finere byfolk.
        Den gangen var dette langt ute på landet, og rundt om lå det bare noen
        gårder.
      </P>

      <P>
        Mine besteforeldre flyttet inn her omkring 1921 og bodde her så lenge de
        levde. Deretter hadde min tante det til vi overtok det i 1992. Vi har
        siden både bygget på og rehabilitert, men mye arbeid gjenstår.
      </P>
      <P>
        Huset er bygget i såkalt laftet plank. Det må våre et av landets første
        ferdighus. Laget på Strømmen Trevarefabrikk, transportert med jernbane
        til Nesttun og derfra med hest og kjerre opp hit og satt opp på nytt.
      </P>
      <P>Og så har vi en del blåbår i hagen</P>
      <P>
        Hilsen fra Sverre: Det er en bil utenfor huset. Den er svart. Det er
        mange fine puslespill på lyngdotten. Og de har ofte is.
      </P>
      <Image src="/img/is.jpg" alt="Bilde av is" />
      <SpacerVertical>
        <BackButton to="/">Tilbake</BackButton>
      </SpacerVertical>
    </div>
  );
}
