import React from "react";
import styled from "styled-components";

const skewDegrees = 3;

interface Props {
  children: any;
}

const SkewedBorder = styled.div`
  padding: 10rem 0;
  text-align: center;
  transform: skewY(-${skewDegrees}deg);
  /* background-color: greenyellow; */
  background-image: linear-gradient(
    to right bottom,
    rgba(0, 0, 0, 0.8),
    rgba(197, 35, 35, 0.8)
  );
  margin-top: 5rem;
  margin-bottom: 10rem;
  margin-left: 2rem;
  margin-right: 2rem;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const UnSkew = styled.div`
  transform: skewY(${skewDegrees}deg);
`;

export const PageDivider: React.FC<Props> = ({ children }) => {
  return (
    <SkewedBorder>
      <UnSkew>{children}</UnSkew>
    </SkewedBorder>
  );
};
